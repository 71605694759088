
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { identity } from "lodash";
import { Modal } from "bootstrap";
import Moment from "moment";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    // Form,
    ErrorMessage,
  },
  data() {
    const route = useRoute();
    const router = useRouter();

    const branches = [];
    const services = [
      {
        value: "27",
        label: "Advisory/Khidmat Nasihat",
      },
      {
        value: "18",
        label: "General Enquiry/Pertanyaan Umum",
      },
      {
        value: "16",
        label: "MyKNP/Khidmat Nasihat Pembiayaan",
      },
      {
        value: "15",
        label: "Reverse Mortgage/Skim Saraan Bercagar",
      },
      {
        value: "10",
        label: "SME Helpdesk/Perniagaan Perusahaan Kecil dan Sederhana ",
      },
    ];

    const appointment_date = moment().add(1, "days").format("YYYY-MM-DD");
    const start_time = moment().format("HH:mm:ss");

    const user = {} as any;

    const pdpaCheck = false;

    const customerNRICToSearch = "";
    const profile = {
      id: "",
      name: "",
      nric: "",
      email: "",
      phone: "",
    };
    return {
      branches,
      isNRIC: null,
      store: useStore(),
      appointment_date,
      start_time,
      services,
      pdpaCheck,
      callType: "",
      user,
      org_id: null,
      customerNRICToSearch,
      profile,
      showCustomer: false,
      customerExist: false,
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_AUTH_USER).then(() => {
        this.user = this.store.getters.getProfile;
        this.org_id = this.user.organisation_id;
      });
    },
    searchCustomerByNRIC() {
      this.customerExist = false
      this.store
        .dispatch(Actions.GET_CUSTOMER_BY_NRIC, this.customerNRICToSearch)
        .then(() => {
          let theProfile = this.store.getters.getCustomerData;
          if (theProfile.nric) {
            this.profile = theProfile;
            this.customerExist = true;
          } else {
            this.profile.nric = this.customerNRICToSearch;
          }
          this.showCustomer = true;
        });
    },
    onCancel() {
      this.showCustomer = false;
      this.customerNRICToSearch = "";
      this.profile = {
        id: "",
        name: "",
        nric: "",
        email: "",
        phone: "",
      };
    },
    registerCustomer() {
      let value = {
        name: this.profile.name,
        nric: this.profile.nric,
        username: this.profile.nric,
        email: this.profile.email,
        phone: this.profile.phone,
        password: "Akpk@12345678",
        cpassword: "Akpk@12345678",
      };

      this.store.dispatch(Actions.PRE_REGISTER_CUSTOMER, value).then(() => {
        this.store
          .dispatch(Actions.GET_CUSTOMER_BY_NRIC, this.profile.nric)
          .then(() => {
            this.profile = this.store.getters.getCustomerData;
            this.startSession();
          });
      });
    },
    startSession() {
      //Set Appointment
      const appointment = {
        id: "",
        slot_id: "",
        fa_id: this.user.id,
        user_id: this.profile.id,
        method_id: 1,
        service_id: 27,
        organisation_id: this.org_id,
        appointment_date: Moment().format("YYYY-MM-DD"),
        slot_type: "appointment",
        start_time: Moment().format("HH:mm:ss"),
        end_time: Moment().add(30, "minutes").format("HH:mm:ss"),
        status: "New",
        fa_reference: this.callType,
        source_type_id: null,
      };

      if (this.callType == "Inbound Call") {
        appointment.source_type_id = 9;
      }

      this.store
        .dispatch(Actions.PRE_STORE_APPOINTMENT, appointment)
        .then(() => {
          let data = this.store.getters.getAppointmentData.data;
          appointment.id = data.id;
          appointment.slot_id = data.id;
          this.store
            .dispatch(Actions.STORE_RAW, {
              type: "appointment",
              user_id: this.profile.id,
              raw: appointment,
            })
            .then(() => {
              //Start the session
              let slot = JSON.parse(JSON.stringify(appointment));
              this.UpdateStatusAppointment(slot);
            });
        });
    },
    UpdateStatusAppointment(slot) {
      slot.raw = {};
      slot.status = "In Progress";

      this.store
        .dispatch(Actions.UPDATE_SLOT, slot)
        .then(() => {
          let slot_log = {
            slot_id: slot.id,
            type: "Updated Appointment Status",
            raw: {
              status: slot.status,
            },
          };

          this.updateLog(slot_log);

          Swal.fire({
            title: "Congratulation!",
            text: "Appointment session has started!",
            icon: "success",
          }).then(() => {
            window.location.replace(
              "#/customers/details/" +
                this.profile.id +
                "/appointment/" +
                slot.id
            );
          });
        })
        .catch((err) => {
          this.showSaveSessionError();
        });
    },
    updateLog(log) {
      this.store.dispatch(Actions.STORE_SLOT_LOG, log).then(() => {
        // console.log("log updated");
      });
    },
    showSaveSessionError() {
      Swal.fire({
        icon: "error",
        title: "Session Error",
        text: "We encountered an issue. Please try again later.",
        footer: "If the problem persists, please contact support.",
      });
    },
    showForm(type) {
      new Modal("#modal_call", {
        backdrop: "static",
        keyboard: false,
      }).show();

      this.callType = type;
    },
    identityTypeOnChange(value) {
      this.isNRIC = value == "NRIC" ?? false;
      const form = this.$refs.form as any;
    },
    onSubmitPreSignUp(value) {
      Swal.fire({
        title: "Loading...",
        text: "Please wait while we are processing your request.",
        icon: "info",
        buttonsStyling: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      });

      setTimeout(() => {
        this.store.dispatch(Actions.STORE_PRE_SIGN_UP, value).then(() => {
          let response = this.store.getters.getPreSignUpData;
          Swal.fire({
            title: "Congratulations!",
            text: "Appointment Created.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
        });
      }, 3000);
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLButtonElement | null>(null);

    const GEFormSchema = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      nric: Yup.string()
        .required()
        .min(12, "NRIC is not valid. Please folow this format eg: 77112303609")
        .max(12, "NRIC is not valid. Please folow this format eg: 771123036094")
        .matches(/^[0-9]+$/, "Must be only digits"),
      phone: Yup.string()
        .required()
        .min(
          9,
          "Phone No is not valid. Please folow this format eg: 01234567890"
        )
        .max(
          12,
          "Phone No is not valid. Please folow this format eg: 01234567890"
        )
        .matches(/^[0-9]+$/, "Must be only digits"),
      email: Yup.string().required().email().label("Email"),
      // org_id: Yup.string().required().label("Branch"),
      service_id: Yup.string().required().label("Enquiry Type"),
    });

    const PreSignUpAdvisorySchema = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      nric: Yup.string()
        .required()
        .min(12, "NRIC is not valid. Please folow this format eg: 77112303609")
        .max(12, "NRIC is not valid. Please folow this format eg: 771123036094")
        .matches(/^[0-9]+$/, "Must be only digits"),
      phone: Yup.string()
        .required()
        .min(
          9,
          "Phone No is not valid. Please folow this format eg: 01234567890"
        )
        .max(
          12,
          "Phone No is not valid. Please folow this format eg: 01234567890"
        )
        .matches(/^[0-9]+$/, "Must be only digits"),
      email: Yup.string().required().email().label("Email"),
    });

    onMounted(() => {
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });

    return {
      GEFormSchema,
      PreSignUpAdvisorySchema,
      submitButton,
    };
  },
  async mounted() {
    this.init();
  },
});
