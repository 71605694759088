<template>
  <table class="table table-striped table-hover table-bordered align-middle">
    <thead>
      <tr align="center">
        <th
          v-for="(column, index) in parameters.columns"
          v-bind:key="index"
          v-html="title(column)"
        ></th>
      </tr>
    </thead>
    <tfoot v-if="footer">
      <tr>
        <th
          v-for="(column, index) in parameters.columns"
          v-bind:key="index"
          v-html="column.footer"
        ></th>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import * as $ from "jquery";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
require("datatables.net");
require("datatables.net-bs5");
require("datatables.net-buttons");
require("datatables.net-buttons-dt");

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-buttons-dt/js/buttons.dataTables.js"

export default {
  name: "Table",
  data() {
    return {
      dataTable: {},
    };
  },
  methods: {
    title(column) {
      return column.title || this.titleCase(column.data);
    },
    titleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  },
  computed: {
    parameters() {
      const store = useStore();
      const { t } = useI18n();

      const token = store.getters.currentUser.token;
      return $.extend(
        {
          serverSide: true,
          processing: true,
        },
        {
          ajax: {
            url: this.ajax,
            beforeSend: function (xhr) {
              xhr.setRequestHeader("Authorization", "Bearer " + token);
            },
          },
          order: this.order,
          search:{return:true},
          columns: this.columns,
          dom: 'lBfrtip',
          aLengthMenu: [
            [10, 25, 50, 100, 200, -1],
            [10, 25, 50, 100, 200, "All"]
        ],
          buttons: ["print","csv"],
          language: {
            emptyTable: t("table.language.emptyTable"),
            info: t("table.language.info"),
            infoEmpty: t("table.language.infoEmpty"),
            infoFiltered: t("table.language.infoFiltered"),
            infoThousands: t("table.language.infoThousands"),
            lengthMenu: t("table.language.lengthMenu"),
            loadingRecords: t("table.language.loadingRecords"),
            processing: t("table.language.processing"),
            search: t("table.language.search"),
            zeroRecords: t("table.language.zeroRecords"),
            thousands: t("table.language.thousands"),
            paginate: {
              first: t("table.language.paginate.first"),
              previous: t("table.language.paginate.previous"),
              next: t("table.language.paginate.next"),
              last: t("table.language.paginate.last"),
            },
            aria: {
              sortAscending: t("table.language.aria.sortAscending"),
              sortDescending: t("table.language.aria.sortDescending"),
            },
          },
          columnSearch: true,
          scrollX: true,
          // createdRow(...args) {
          //   this.$emit("created-row", ...args);
          // },
          // drawCallback(...args) {
          //   this.$emit("draw", ...args);
          // },
          // footerCallback(...args) {
          //   this.$emit("footer", ...args);
          // },
          // formatNumber(...args) {
          //   this.$emit("format", ...args);
          // },
          // headerCallback(...args) {
          //   this.$emit("header", ...args);
          // },
          // infoCallback(...args) {
          //   this.$emit("info", ...args);
          // },
          // initComplete(...args) {
          //   this.$emit("init", ...args);
          // },
          // preDrawCallback(...args) {
          //   this.$emit("pre-draw", ...args);
          // },
          // rowCallback(...args) {
          //   this.$emit("draw-row", ...args);
          // },
          // stateLoadCallback(...args) {
          //   this.$emit("state-load", ...args);
          // },
          // stateLoaded(...args) {
          //   this.$emit("state-loaded", ...args);
          // },
          // stateLoadParams(...args) {
          //   this.$emit("state-load-params", ...args);
          // },
          // stateSaveCallback(...args) {
          //   this.$emit("state-save", ...args);
          // },
          // stateSaveParams(...args) {
          //   this.$emit("state-save-params", ...args);
          // },
        },
        this.options
      );
    },
  },
  props: {
    footer: { default: false },
    order: { default: [] },
    columns: { type: Array },
    ajax: { default: "" },
    options: {},
  },
  async mounted() {
    const store = useStore();
    if(await store.getters.currentUser.token) {
      this.dataTable = $(this.$el).DataTable(this.parameters);
    }
  },
  async unmounted() {
    const store = useStore();
    if(await store.getters.currentUser.token) {
      this.dataTable.destroy();
    }
  },
};
</script>

<style lang="css" scoped>
@import "../../node_modules/datatables.net-bs5/css/dataTables.bootstrap5.css";
@import "../../node_modules/datatables.net-buttons-dt/css/buttons.dataTables.css";

div.dataTables_wrapper div.dataTables_length select {
  width:50px !important;
}
</style>
