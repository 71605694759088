import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WidgetUpcomingAssignments = _resolveComponent("WidgetUpcomingAssignments")!
  const _component_WidgetUpcomingAppointments = _resolveComponent("WidgetUpcomingAppointments")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isFA)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_WidgetUpcomingAssignments, {
              "widget-classes": "card-xxl-stretch mb-5 mb-xl-8",
              "chart-color": "primary",
              "chart-height": "300"
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_WidgetUpcomingAppointments, {
          "widget-classes": "card-xxl-stretch mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "300"
        })
      ])
    ])
  ], 64))
}