
import { defineComponent } from "vue";
import * as $ from "jquery";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { Form, Field, ErrorMessage } from "vee-validate";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import Table from "@/components/Table.vue";

export default defineComponent({
  name: "kt-widget-5",
  props: {
    widgetClasses: String,
  },
  components: {
    // Form,
    Field,
    ErrorMessage,
    Table,
  },
  async mounted() {
    this.init();
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_AUTH_USER).then(() => {
        const data = this.store.getters.getProfile;
        this.user = Object.assign(this.user, data);

        this.store.dispatch(Actions.GET_ASSIGNMENTS, this.user.id).then(() => {
          this.assignments = this.store.getters.getAssignmentsData;
        });
      });

      this.store.dispatch(Actions.GET_BRANCHES).then(() => {
        this.branches = this.store.getters.getBranchesData;
      });

      this.store.dispatch(Actions.GET_FA_LIST).then(() => {
        this.fas = this.store.getters.getFaListData;
      });

      // this.store.dispatch(Actions.GET_PFP_REPORT).then(() => {
      //   this.pfpReport = this.store.getters.getPfpReportData;

      //   this.isReportReady = true;
      // });
    },
    getHumanDate(date) {
      return moment(date).format("DD/MM/YYYY h:mm:ss a");
    },
    dtFilter() {
      const table = this.$refs.table as any;

      const column_0 =
        this.filter.byBatch == "Batch 1"
          ? "2022-05-10"
          : this.filter.byBatch == "Batch 2"
          ? "2022-06-14|2022-06-13"
          : "";
      const column_5 =
        this.filter.byProfiling == "A"
          ? "Unemployed"
          : this.filter.byProfiling == "B"
          ? "Self-Employed|Employed"
          : "";

      table?.dataTable
        .column(0)
        .search(column_0, true, false)
        .column(7)
        .search(this.filter.byStatus ?? "")
        .column(6)
        .search(this.filter.byPackage ?? "")
        .column(13)
        .search(+this.filter.byAdvisor ?? "")
        .column(5)
        .search(column_5, true, false)
        .draw();
    },
    getBatchNo(date, user_id) {
      // console.log(user_id);
      let batchNo = "";
      if (moment(date).format("DD/MM/YYYY") == "10/05/2022") {
        batchNo = "Batch 1";
      } else if (
        moment(date).format("DD/MM/YYYY") == "13/06/2022" ||
        moment(date).format("DD/MM/YYYY") == "14/06/2022"
      ) {
        batchNo = "Batch 2";
      }

      return batchNo;
    },
  },
  data() {
    const store = useStore();
    const route = useRoute();
    const fas = [];

    const assignments = [];
    const pfpReport = [];
    const isReportReady = false;

    const status = "";

    const statuses = ["PFP Report Generated", "PFP Report Incomplete"];
    const packages = ["Interest/Profit waiver and Reduce Instalment"];
    const profilings = ["A", "B"];

    const showPfpReport = false;

    const filter = {
      byBatch: "",
      byPackage: "",
      byStatus: "",
      byProfiling: "",
      byAdvisor: "",
    };

    // const batches = [
    //   {
    //     value:"2022-05-10",
    //     label:"Batch 1",
    //   },
    //   {
    //     value:"2022-06-13",
    //     label:"Batch 2",
    //   }
    // ]

    const batches = ["Batch 1", "Batch 2"];
    const branches = [];

    const url = process.env.VUE_APP_API_URL + "/pfp-logs"; // ✅

    return {
      showPfpReport,
      branches,
      user: {
        id: null,
        name: null,
        email: null,
        username: null,
        phone: null,
        gender: null,
        birthdate: null,
      },
      filter,
      fas,
      packages,
      batches,
      store,
      route,
      profilings,
      assignments,
      pfpReport,
      status,
      statuses,
      isReportReady,
      ajax: url,
      order: [[0, "asc"]],
      columns: [
        // {
        //   data: "id",
        //   title: "No",
        //   defaultContent: "-",
        //   className: "text-center",
        //   render: function (data, type, row, meta) {
        //     return meta.row + meta.settings._iDisplayStart + 1;
        //   },
        // },
        {
          data: "appointment_date",
          defaultContent: "-",
          title: "Batch",
          render: function (data, type, row, meta) {
            let batchNo = "";
            if (moment(data).format("DD/MM/YYYY") == "10/05/2022") {
              batchNo = "Batch 1";
            } else if (
              moment(data).format("DD/MM/YYYY") == "13/06/2022" ||
              moment(data).format("DD/MM/YYYY") == "14/06/2022"
            ) {
              batchNo = "Batch 2";
            }

            return batchNo;
          },
        },
        {
          data: "user.name",
          title: "Customer Name",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "user.nric",
          title: "IC No",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "user.email",
          title: "Email",
          orderable: false,
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "user.phone",
          title: "Phone No",
          orderable: false,
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "user.detail.employment_status",
          title: "PFP",
          defaultContent: "-",
          className: "text-start",
          render: function (data, type, row, meta) {
            return data == "Unemployed" ? "A" : "B";
          },
        },
        {
          data: "user.detail.urs_package",
          title: "Package",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "user.pfp_logs_latest.status",
          title: "PFP Status",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "user.pfp_logs_latest.raw.remarks",
          title: "Remarks",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "user.pfp_logs_latest.created_at",
          title: "Date PFP Generated/Withdraw",
          defaultContent: "-",
          className: "text-start",
          render: function (data, type, row, meta) {
            if (data) {
              return moment(data).format("DD/MM/YYYY h:mm:ss a");
            } else {
              return "-";
            }
          },
        },

        // {
        //   data: "user.pfp_logs",
        //   title: "Remarks",
        //   defaultContent: "-",
        //   className: "text-start",
        //   render: function (data, type, row, meta) {
        //     return data.length > 0 && data[0].status == "Withdrawn from PFP"
        //       ? data[0].raw.remarks
        //       : "-";
        //   },
        // },
        // {
        //   data: "user.pfp_logs",
        //   title: "Report Date Generated",
        //   defaultContent: "-",
        //   className: "text-start",
        //   render: function (data, type, row, meta) {
        //     return data.length > 0
        //       ? moment(data[0].created_at).format("DD/MM/YYYY h:mm:ss a")
        //       : "-";
        //   },
        // },
        {
          data: "fa.name",
          title: "Advisor Name",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "fa.email",
          title: "Advisor Email",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "fa.phone",
          title: "Advisor Phone No.",
          defaultContent: "-",
          className: "text-start",
        },
          {
            data: "fa_id",
            title: "FA ID",
            type: "decimals",
            // visible: false,
            defaultContent: "-",
            className: "text-start",
          },
      ],
    };
  },
});
