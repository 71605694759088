
import { defineComponent, onMounted } from "vue";

import WidgetBranchPerfomance from "@/components/widgets/WidgetBranchPerfomance.vue";
import WidgetUpcomingAppointments from "@/components/widgets/WidgetUpcomingAppointments.vue";
import WidgetUpcomingAssignments from "@/components/widgets/WidgetUpcomingAssignments.vue";
import WidgetServices from "@/components/widgets/WidgetServices.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";

export default defineComponent({
  name: "dashboard",
  components: {
    // WidgetBranchPerfomance,
    WidgetUpcomingAppointments,
    WidgetUpcomingAssignments,
    // WidgetServices,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  },
  data() {
    const store = useStore();
    const roles = store.getters?.currentUser?.roles?.map((a) => a.name);

    const isAdmin = roles?.includes("admin");
    const isFA = roles?.includes("financial-advisor");
    const isViewer = roles?.includes("viewer");
    const isBranchHead = roles?.includes("branch-head");

    return {
      isAdmin,
      isFA,
      isViewer,
      isBranchHead,
    };
  },
});
