
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import * as $ from "jquery";
import { Actions } from "@/store/enums/StoreEnums";
import Table from "@/components/Table.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";
import CallForm from "./../../views/customers/appointments/Call.vue"

export default defineComponent({
  name: "kt-widget-5",
  props: {
    widgetClasses: String,
  },
  components: {
    Field,
    Table,
    CallForm
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_BRANCHES).then(() => {
        this.branches = this.store.getters.getBranchesData;
      });

      this.store.dispatch(Actions.GET_FA_OFFICES).then(() => {
        this.advOffices = this.store.getters.getAllAdvisoryOfficessData;
      });

      if (this.isAdmin) {
        this.store.dispatch(Actions.GET_FA_LIST).then(() => {
          this.advisors = this.store.getters.getFaListData;
          this.advisors[0] = "All";
        });
      } else if (this.isBranchHead) {
        this.store.dispatch(Actions.GET_FA_LIST_BY_ORG_ID).then(() => {
          this.advisors = this.store.getters.getFaListByOrgIdData;
          this.advisors[0] = "All";
        });
      }
    },
    getHumanDate(date, dateOnly = false) {
      if (dateOnly) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return moment(date).format("DD/MM/YYYY h:mm:ss a");
      }
    },
    onChangeBranch() {
      this.filter.byAdvisorOffice = "";
      this.filter.byOrg = this.filter.byBranch;
      this.dtFilter();
    },
    onChangeOffice() {
      this.filter.byBranch = "";
      this.filter.byOrg = this.filter.byAdvisorOffice;
      this.dtFilter();
    },
    dtFilter() {
      const table = this.$refs.tableAppointment as any;
      const dateFilter = this.filter.byDate
        ? `?datefilter=${this.filter.byDate}`
        : "";
      const dateCreatedFilter = this.filter.byCreatedDate
        ? `&dateCreatedfilter=${this.filter.byCreatedDate}`
        : "";

      if (this.filter.byMethod == "All") this.filter.byMethod = "";
      if (this.filter.byService == "All") this.filter.byService = "";
      if (this.filter.byStatus == "All") this.filter.byStatus = "";
      // if (this.filter.byBranch !== "") this.filter.byAdvisorOffice = "";
      // if (this.filter.byAdvisorOffice !== ""){
      //   this.filter.byBranch = "All"
      //   console.log(this.filter.byAdvisorOffice);
      //   console.log(this.filter.byBranch);
      // }
      if (this.filter.byAdvisor == "All") this.filter.byAdvisor = "";
      // if (this.filter.byLatestService == "All")
      //   this.filter.byLatestService = "";

      table?.dataTable.ajax
        .url(this.ajax + dateFilter + dateCreatedFilter)
        .load()
        // .ajax.url(this.ajax + dateCreatedFilter).load()
        .column(4)
        .search(this.filter.byMethod ?? "")
        .column(5)
        .search(this.filter.byService ?? "")
        // .column(7)
        // .search(this.filter.byLatestService ?? "")
        .column(6)
        .search(this.filter.byAdvisor ?? "")
        .column(7)
        .search(this.filter.byOrg ?? "")
        .column(10)
        .search(this.filter.byStatus ?? "")
        .column(9)
        .draw();
    },
  },
  async mounted() {
    this.init();

    console.log();
  },
  setup() {
    // const store = useStore();
    // return {
    // }
  },
  data() {
    const route = useRoute();
    const store = useStore();
    const token = store.getters.currentUser.token;
    const cstmr_id = route.params.id ?? null;

    const methods = ["All", "Phone", "Walk In", "Video Conference"];

    const statuses = [
      "All",
      "New",
      "In Progress",
      "Case Resolved",
      "Case Closed",
    ];

    const services = [
      "All",
      "Advisory",
      "Financial Resolution - Household",
      "Repayment Assistance",
      "Small Debt Resolution Scheme (SDRS)",
      "Financial Skills - Business (SME)",
      "MIDF Second Chance Financing",
      "URUS Help",
      "Financial Advisory Services (MyKNP)",
      "Personalized Financial Plan",
      "Reverse Mortgage - Skim Saraan Bercagar (SSB)",
      "Financial Scoring - Household",
      "Financial Planning - Household",
      "Financial Skills - Household",
      "General Enquiry",
      "Credit Rating Provider",
      "Account Enquiry",
      "Business (SME)",
      "CCRIS Report/eCCRIS",
      "Post DMP",
    ];

    const filter = {
      byMethod: "",
      byStatus: "",
      byService: "",
      byAdvisor: "",
      byAdvisorOffice: "",
      byBranch: "",
      // byLatestService: "",
      byDate: [],
      byCreatedDate: [],
      byOrg: "",
    };

    const roles = store.getters?.currentUser?.roles?.map((a) => a.name);

    const isAdmin = roles?.includes("admin");
    const isFA = roles?.includes("financial-advisor");
    const isViewer = roles?.includes("viewer");
    const isBranchHead = roles?.includes("branch-head");

    let url = null;

    url = process.env.VUE_APP_API_URL + "/slots/get-appointments"; // ✅

    // const startOfMonth = moment().startOf("month").format("DD/MM/YYYY");
    // const endOfMonth = moment().endOf("month").format("DD/MM/YYYY");

    const startOfMonth = moment().format("YYYY-MM-DD");
    const endOfMonth = moment().add(7, "d").format("YYYY-MM-DD");

    return {
      store,
      roles,
      isAdmin,
      isBranchHead,
      isViewer,
      isFA,
      token,
      methods,
      advisors: [],
      branches: [],
      advOffices: [],
      startOfMonth,
      endOfMonth,
      statuses,
      services,
      filter,
      ajax: url,
      order: [[8, "asc"]],
      columns: [
        {
          data: "id",
          title: "No",
          defaultContent: "-",
          className: "text-center",
          render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
        },
        // {
        //   data: "created_at",
        //   defaultContent: "-",
        //   title: "Created",
        //   render: function (data, settings, row) {
        //     return moment(row.created_at).format("DD/MM/YYYY h:mm:ss a");
        //   },
        // },
        {
          data: "name",
          name: "name",
          defaultContent: "-",
          title: "Name",
        },
        {
          data: "nric",
          name: "nric",
          defaultContent: "-",
          title: "ID",
        },
        {
          data: "phone",
          name: "phone",
          defaultContent: "-",
          title: "Phone",
        },
        {
          data: "method",
          name: "method",
          defaultContent: "-",
          title: "Method",
        },
        {
          data: "service",
          name: "service",
          defaultContent: "-",
          title: "Service",
        },
        // {
        //   data: "latest_service",
        //   name: "latest_service",
        //   defaultContent: "-",
        //   title: "Latest Service",
        // },
        {
          data: "advisor",
          name: "advisor",
          defaultContent: "-",
          title: "Financial Advisor",
        },
        {
          data: "organisation",
          name: "organisation",
          defaultContent: "-",
          title: "Branch/<br/>Advisory Office",
        },
        {
          data: "appointment_date",
          defaultContent: "-",
          title: "Appointment Date",
          render: function (data, settings, row) {
            return moment(row.appointment_date).format("DD/MM/YYYY");
          },
        },
        {
          data: "source",
          name: "source",
          defaultContent: "-",
          title: "Source"
        },
        // {
        //   data: "updated_at",
        //   defaultContent: "-",
        //   title: "Last Modified",
        //   render: function (data, settings, row) {
        //     return moment(row.updated_at).format("DD/MM/YYYY hh:mm:ss");
        //   },
        // },
        // {
        //   data: null,
        //   name: "start_time",
        //   defaultContent: "-",
        //   title: "Start - End",
        //   render: function (data, settings, row) {
        //     return row.start_time + "-" + row.end_time;
        //   },
        // },
        {
          data: "status",
          defaultContent: "-",
          title: "Status",
        },
        {
          data: "action",
          defaultContent: "-",
          title: "Action",
        },
      ],
    };
  },
});
